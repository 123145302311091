@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-poppins;
  }
}

@layer components {
  .poppins-thin {
    @apply font-poppins font-thin;
  }

  .poppins-extralight {
    @apply font-poppins font-extralight;
  }

  .poppins-light {
    @apply font-poppins font-light;
  }

  .poppins-regular {
    @apply font-poppins font-normal;
  }

  .poppins-medium {
    @apply font-poppins font-medium;
  }

  .poppins-semibold {
    @apply font-poppins font-semibold;
  }

  .poppins-bold {
    @apply font-poppins font-bold;
  }

  .poppins-extrabold {
    @apply font-poppins font-extrabold;
  }

  .poppins-black {
    @apply font-poppins font-black;
  }

  .poppins-thin-italic {
    @apply font-poppins font-thin italic;
  }

  .poppins-extralight-italic {
    @apply font-poppins font-extralight italic;
  }

  .poppins-light-italic {
    @apply font-poppins font-light italic;
  }

  .poppins-regular-italic {
    @apply font-poppins font-normal italic;
  }

  .poppins-medium-italic {
    @apply font-poppins font-medium italic;
  }

  .poppins-semibold-italic {
    @apply font-poppins font-semibold italic;
  }

  .poppins-bold-italic {
    @apply font-poppins font-bold italic;
  }

  .poppins-extrabold-italic {
    @apply font-poppins font-extrabold italic;
  }

  .poppins-black-italic {
    @apply font-poppins font-black italic;
  }
}